import React, { useContext } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
// hooks imports
import useSignUpValidation from "../../hooks/useSignUpValidation";
// components imports
import Toast from "../UI/Toast";
// styles imports
import "./CustomerSignup.css";

const CustomerSignup = () => {
  const INITIAL_STATE = {
    name: "",
    email: "",
    checkbox: "",
  };

  // ^ context
  const { accessToken, toOnBoardingCard, toOnBoardingKybCard, setAuthConfig } =
    useContext(OnBoardingContext);

  const validateForm = (values) => {
    let errors = {};

    // Validation rules
    if (!values.name) {
      errors.name = "Please enter a valid business name";
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.email) {
      errors.email = "Please enter your email address";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!values.checkbox) {
      errors.checkbox = "Consent to be checked";
    }

    return errors;
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoading,
    showToast,
    toastMessage,
    isSubmitting,
    getBizAccounts,
    // gives the list of biz accounts
    bizAccountListDetails,
  } = useSignUpValidation(
    INITIAL_STATE,
    validateForm,
    accessToken,
    toOnBoardingCard,
    setAuthConfig
  );

  React.useEffect(() => {
    getBizAccounts();
  }, []);

  return (
    <div className="card-wrapper">
      <div className="sign-up-heading">
        <p>Create your Business Account</p>
      </div>

      <form className="form-wrapper" autoComplete="off" onSubmit={handleSubmit}>
        <div className="field-wrapper">
          <label>Business Name</label>
          <input
            autoComplete="false"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.name && <p className="error-message">{errors.name}</p>}
        </div>

        <div className="field-wrapper">
          <label>Business Email</label>
          <input
            autoComplete="false"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>

        <div className="terms-container">
          <div className="checkbox-block">
            <input
              type="checkbox"
              name="checkbox"
              checked={values.checkbox}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <p className="terms-text">
            {/* By clicking Create Account, you agree to our
            <a
              href="https://docs.google.com/document/d/1BAhl8s__qM7PSJTYnvSdNCl5gMVhYuMl/edit?usp=sharing&ouid=116708813578326090433&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions, Privacy Policy and Service Agreement.
            </a> */}
            {/* updated text */}
            You agree to Airwallex
            <a
              href="https://www.airwallex.com/sg/terms/general-terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>
            ,
            <a
              href="https://www.airwallex.com/sg/terms/privacy-policy#privacy-policy__scope-of-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            and scale user
            <a
              href="https://www.airwallex.com/sg/terms/decentro"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms
            </a>
          </p>
        </div>
        <div className="consent-error-message">
          {errors.checkbox && <p>{errors.checkbox}</p>}
        </div>

        {/* Toast component */}
        {showToast && (
          <Toast message={toastMessage || "Retry, Something went wrong!"} />
        )}

        <button
          className={isSubmitting ? `theme-btn` : `theme-btn__disabled`}
          type="submit"
        >
          {!!isLoading ? (
            <div className="custom-loader"></div>
          ) : (
            "Create Account"
          )}
        </button>
      </form>

      {/* biz account list */}

      {/* <div class="biz-list"> */}
      {/* </div> */}
      {!!bizAccountListDetails.data ? (
        bizAccountListDetails.data.map((details) => (
          <div
            className="biz-card-wrapper"
            onClick={() =>
              toOnBoardingKybCard(
                details.business_account_unique_reference_number
              )
            }
          >
            <p>
              <span className="detail-label">Alias</span>
              <span className="detail-value">
                {details?.account_alias || "-"}
              </span>
            </p>
            <p>
              <span className={`detail-label`}>Status</span>
              <span className={`detail-value ${details?.account_status}`}>
                {details?.account_status || "-"}
              </span>
            </p>
            <p>
              <span className="detail-label">Type</span>
              <span className="detail-value">
                {details?.account_type || "-"}
              </span>
            </p>
            <p>
              <span className="detail-label">Account</span>
              <span className="detail-value">
                {details?.business_account_unique_reference_number || "-"}
              </span>
            </p>
          </div>
        ))
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default CustomerSignup;
