import React from "react";
// styles imports
import "./SuccessModal.css";

const SuccessModalKYB = () => {
  return (
    <div className="success-card-wrapper">
      <div className="success-content-container">
        <img src="/images/success-icon.svg" alt="success-icon" />
        <p className="message-heading">Congratulations!</p>
        <p className="message-heading-subtitle">
          We have successfully registered your details.
        </p>

        <p className="footer-text">
          Documents for KYB have been submitted successfully and might take a
          few days to complete. <br /> Once we receive an update, we will
          activate your account. <br /> If any additional documents are
          required, we will contact you promptly.
          <br />
        </p>
      </div>
    </div>
  )
};

export default SuccessModalKYB;
