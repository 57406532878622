import React from "react";
// context imports
import { useContext } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
// component imports
import BackgroundWrapper from "../UI/BackgroundWrapper";
import CustomerSignIn from "../CustomerSignIn/CustomerSignIn";
import CustomerSignup from "../CustomerSignup/CustomerSignup";
import Onboarding from "../OnBoarding";
import OnboardingKyb from "../OnBoardingKyb";
import SuccessModal from "../SuccessModal/SuccessModal";
import SuccessModalKYB from "../SuccessModal/SuccessModalKYB";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const OnBoardingLayout = () => {
  // ^ context
  const { cardType } = useContext(OnBoardingContext);

  // * Card View Variables Enum
  const ONBOARDING_CARD = {
    signIn: <CustomerSignIn />,
    signUp: <CustomerSignup />,
    Onboarding: <Onboarding />,
    OnboardingKyb: <OnboardingKyb />,
    success: <SuccessModal />,
    successKyb: <SuccessModalKYB/>,
    loading: <LoadingScreen />,
  };

  return (
    <>
      <BackgroundWrapper>
        {/* <CustomerSignIn /> */}
        {/* <CustomerSignup /> */}
        {/* <SuccessModal /> */}
        {/* <Onboarding /> */}
        {/* <LoadingScreen /> */}
        {ONBOARDING_CARD[cardType]}
      </BackgroundWrapper>
    </>
  );
};

export default OnBoardingLayout;
