import React, { useContext } from "react";
import OnBoardingContext from "../../context/OnBoardingContext";
// hooks imports
import useFormValidation from "../../hooks/useFormValidation";
import Toast from "../UI/Toast";
// styles imports
import "./CustomerSignIn.css";

const CustomerSignIn = () => {
  const INITIAL_STATE = {
    email: "",
    password: "",
  };

  const validateForm = (values) => {
    let errors = {};

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.email) {
      errors.email = "Please enter your email address";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Please enter a valid email address";
    }

    const passwordRegex = /^.{6,}$/;

    if (!values.password) {
      errors.password = "Please enter your password";
    } else if (!passwordRegex.test(values.password)) {
      errors.password = "Please enter a valid password";
    }

    return errors;
  };

  // ^ context
  const { toSignUpCard, setAccessToken } = useContext(OnBoardingContext);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoading,
    showToast,
    toastMessage,
    isSubmitting,
  } = useFormValidation(
    INITIAL_STATE,
    validateForm,
    toSignUpCard,
    setAccessToken
  );

  return (
    <div className="card-wrapper">
      <div className="sign-in-heading">
        <p>Sign in to your account</p>
      </div>

      <form className="form-wrapper" autoComplete="off" onSubmit={handleSubmit}>
        <div className="field-wrapper">
          <label>Email</label>
          <input
            autoComplete="false"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            // placeholder="Email"
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>

        <div className="field-wrapper">
          <label>Password</label>
          <input
            autoComplete="false"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            // placeholder="Email"
          />
          {errors.password && (
            <p className="error-message">{errors.password}</p>
          )}
        </div>
       
        {/* Toast component */}
        {showToast && (
          <Toast message={toastMessage || "Retry , Something went wrong!"} />
        )}

        <button
          className={isSubmitting ? `theme-btn` : `theme-btn__disabled`}
          type="submit"
        >
          {!!isLoading ? <div className="custom-loader"></div> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default CustomerSignIn;
