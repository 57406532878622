import { useEffect, useState } from "react";
import axios from "axios";
import { APIConfig } from "../services/apiConfiguration";
// services imports
import { randomUUID } from "../services/randomUUID";
import apiEndpoint from "../services/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const useSignUpValidation = (
  initialState,
  validate,
  accessToken,
  toOnBoardingCard,
  setAuthConfig
) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Toast state
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  //& Biz accounts list state
  const [bizAccountListDetails, setBizAccountListDetails] = useState([]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setValues({
        ...values,
        [name]: checked,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }

    setErrors((prevState) => ({
      ...prevState,
      [name]: touched[name] ? validate({ ...values, [name]: value })[name] : "",
    }));
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    const validationErrors = validate(values);
    setErrors((prevState) => ({
      ...prevState,
      [name]: validationErrors[name] || "",
    }));
    setTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);

    if (isSubmitting && Object.keys(validationErrors).length === 0) {
      signUpSubmit();
    }
  };

  // & sign up submit API hit
  const signUpSubmit = () => {
    setIsLoading(true);
    setShowToast(false);

    let signUpPayload = {
      reference_id: randomUUID(),
      business_name: values.name,
      business_email: values.email,
      customer_consent: {
        agreed_to_data_usage: values.checkbox,
        agreed_to_terms_and_conditions: values.checkbox,
        // agreed_to_data_usage: true,
        // agreed_to_terms_and_conditions: true,
      },
      generate_auth_code: true,
    };

    // accessToken
    APIConfig.API_Client.defaults.headers.post["Authorization"] =
      "Bearer " + accessToken;

    //  API Call logic for Sign UP
    APIConfig.API_Client.post(
      apiEndpoint.USER_SIGN_UP.baseUrl + apiEndpoint.USER_SIGN_UP.endpoint,
      signUpPayload,
      { cancelToken: source.token }
    )

      .then((response) => {
        setIsLoading(false);

        setAuthConfig(response.data.data.auth_config);

        // * ON SUCCESSFUL SIGNUP
        toOnBoardingCard();
      })
      .catch((error) => {
        setIsLoading(false);
        setShowToast(true);
        setToastMessage(error.response.data.message);
      });
  };

  // & sign up submit API hit
  const getBizAccounts = () => {
    // setIsLoading(true);
    // setShowToast(false);

    // let signUpPayload = {
    //   reference_id: randomUUID(),
    //   business_name: values.name,
    //   business_email: values.email,
    //   customer_consent: {
    //     agreed_to_data_usage: values.checkbox,
    //     agreed_to_terms_and_conditions: values.checkbox,
    //     // agreed_to_data_usage: true,
    //     // agreed_to_terms_and_conditions: true,
    //   },
    //   generate_auth_code: true,
    // };

    // accessToken
    APIConfig.API_Client.defaults.headers.get["Authorization"] =
      "Bearer " + accessToken;

    //  API Call logic for Sign UP
    APIConfig.API_Client.get(
      apiEndpoint.GET_BIZ_ACCOUNT.baseUrl +
        apiEndpoint.GET_BIZ_ACCOUNT.endpoint,
      { cancelToken: source.token }
    )

      .then((response) => {
        setBizAccountListDetails(response.data);
        console.log(
          "🚀 🚀 🚀 ~ file: useSignUpValidation.js:145 ~ .then ~ response:",
          response
        );
        // setIsLoading(false);

        // setAuthConfig(response.data.data.auth_config);

        // // * ON SUCCESSFUL SIGNUP
        // toOnBoardingCard();
      })
      .catch((error) => {
        console.log(
          "🚀 🚀 🚀 ~ file: useSignUpValidation.js:154 ~ getBizAccounts ~ error:",
          error
        );
        // setIsLoading(false);
        // setShowToast(true);
        // setToastMessage(error.response.data.message);
      });
  };

  // useEffect(() => {
  //   if (accessToken) {
  //     getBizAccounts();
  //   }
  // }, [accessToken]);

  useEffect(() => {
    const validationErrors = validate(values);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
    } else {
      setIsSubmitting(false);
    }
  }, [values, validate]);

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isLoading,
    isSubmitting,
    showToast,
    setShowToast,
    toastMessage,
    getBizAccounts,
    bizAccountListDetails,
  };
};

export default useSignUpValidation;
