//  context imports
import React, { createContext, useState } from "react";
import { APIConfig } from "../services/apiConfiguration";
import apiEndpoint from "../services/endpoint";
import axios from "axios";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OnBoardingContext = createContext();

export const OnBoardingProvider = ({ children }) => {
  // * Type of card state
  const [cardType, setCardType] = useState("signIn");
  // Types of Card Available
  // signIn
  // signUp
  // Onboarding
  // OnboardingKyb
  // success
  // successKyb
  // loading

  // * For Access Token
  const [accessToken, setAccessToken] = useState("");

  // * For authConfig params
  const [authConfig, setAuthConfig] = useState({});
  const [kybAuthConfig, setKybAuthConfig] = useState({});

  // & Change to SignUp
  const toSignUpCard = () => {
    setCardType("signUp");
  };

  // & Change to Onboarding
  const toOnBoardingCard = () => {
    setCardType("Onboarding");
  };

  // & Change to OnboardingKyb
  const toOnboardingKybCard = () => {
    setCardType("OnboardingKyb");
  };

  // & Change to Onboarding
  const toOnBoardingKybCard = (consumer_bank_account_urn) => {
    generateAuthCode(consumer_bank_account_urn);
  };

  // & Change to SuccessPage
  const toSuccessCard = () => {
    setCardType("success");
  };

  // & Change to SuccessPage
  const toSuccessCardKyb = () => {
    setCardType("successKyb");
  };

  // & Change to Loading screen
  const toLoadingScreen = () => {
    setCardType("loading");
  };

  // & generate Auth code KYB // SIGN up page
  const generateAuthCode = (consumer_bank_account_urn) => {
    toLoadingScreen();

    APIConfig.API_Client.defaults.headers.get["Authorization"] =
      "Bearer " + accessToken;

    //  API Call logic for Sign UP
    APIConfig.API_Client.get(
      apiEndpoint.GENERATE_AUTH_CODE.baseUrl +
        apiEndpoint.GENERATE_AUTH_CODE.endpoint +
        `/${consumer_bank_account_urn}/generate_auth_code`,
      { cancelToken: source.token }
    )
      .then((response) => {
        setKybAuthConfig(response.data.data);
        if (response.data.data) {
          // & mount kyb Component
          // setCardType("OnboardingKyb");
          toOnboardingKybCard();
        }
      })
      .catch((error) => {
        console.log("🚀 🚀 🚀 ~~ getBizAccounts ~ error:", error);
      });
  };

  return (
    <OnBoardingContext.Provider
      value={{
        cardType,
        toSignUpCard,
        toOnBoardingCard,
        toOnBoardingKybCard,
        toSuccessCard,
        toSuccessCardKyb,
        toLoadingScreen,
        accessToken,
        setAccessToken,
        authConfig,
        setAuthConfig,
        kybAuthConfig,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};

export default OnBoardingContext;
