import { useEffect, useState } from "react";
import axios from "axios";
// axios imports
import { setAuthTokens } from "axios-jwt";
// api configuration imports
import {
  APIConfig,
  AuthResponseToAuthTokens,
} from "../services/apiConfiguration";
import apiEndpoint from "../services/endpoint";

const useFormValidation = (
  initialState,
  validate,
  toSignUpCard,
  setAccessToken
) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Toast state
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });

    // Clear error for the field on change
    setErrors((prevState) => ({
      ...prevState,
      [name]: touched[name] ? validate({ ...values, [name]: value })[name] : "",
    }));
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    const validationErrors = validate(values);
    setErrors((prevState) => ({
      ...prevState,
      [name]: validationErrors[name] || "",
    }));
    setTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);

    if (isSubmitting && Object.keys(validationErrors).length === 0) {
      loginSubmit();
    }
  };

  // & submit handler API hit
  const loginSubmit = () => {
    setIsLoading(true);
    setShowToast(false);
    // ^ To be used when login is successful !
    // toSignUpCard();

    if (isSubmitting) {
      localStorage.clear();

      axios
        .post(apiEndpoint.USER_SIGN_IN.baseUrl + apiEndpoint.USER_SIGN_IN.endpoint, {
          email: values.email,
          password: values.password,
          // password: finalEncryptedPassword,
        })
        .then((response) => {
          setIsLoading(false);

          if (response.status === 200) {
            // * ON SUCCESSFUL LOGIN
            toSignUpCard();

            setAuthTokens(AuthResponseToAuthTokens(response.data));

            setAccessToken(response.data.data.access_token);
            localStorage.setItem(
              "user",
              JSON.stringify({
                name: response.data.name,
                email: response.data.email,
                client_id: response.data.client_id,
              })
            );
            localStorage.setItem("loggedInFlag", true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setShowToast(true);
          setToastMessage(
            error?.response?.data?.message ||
              "Something went wrong , Try Again !"
          );
        });
    }
  };

  useEffect(() => {
    const validationErrors = validate(values);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
    } else {
      setIsSubmitting(false);
    }
  }, [values, validate]);

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isLoading,
    isSubmitting,
    loginSubmit,
    showToast,
    setShowToast,
    toastMessage,
  };
};

export default useFormValidation;
