import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { init, createElement } from "@airwallex/components-sdk";
// import { useNotification } from '../../utils/useNotification';
import Snackbar from "@mui/material/Snackbar";
import OnBoardingContext from "../context/OnBoardingContext";

const OnboardingKyb = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // ^ context
  const { kybAuthConfig, toSuccessCard, toSuccessCardKyb } =
    useContext(OnBoardingContext);

  // handle toast close
  const onToastClose = () => {
    setToastOpen(false);
  };

  const handleCancel = () => {
    window.alert("Cancel");
  };

  const handleSuccess = () => {
    // history.push("/success");
    // ! to add a success card for kyb
    toSuccessCardKyb();
  };

  const handleError = () => {
    setToastMessage("Oops, something is wrong");
    setToastOpen(true);
  };

  const handleReady = (event) => {
    console.log(event);
    if (event.type === "consent") {
      setLoading(false);
    } else {
      if (event.kybStatus !== "INIT" && event.kybStatus !== "APPROVED") {
        // history.push("/success");
        toSuccessCard();
      } else {
        setToastMessage("Your verification process has started!");
        setToastOpen(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let element = null;
    const mountKybElement = async () => {
      let {
        auth_code: authCode,
        client_id: clientId,
        code_verifier: codeVerifier,
        env,
        lang_key: langKey,
      } = kybAuthConfig;

      // initialize Airwallex-Onboarding instance on window
      await init({
        authCode,
        codeVerifier,
        env,
        clientId,
        langKey,
        elements: ["paymentsKyb"],
      });

      // create onboarding element
      element = await createElement("paymentsKyb", {
        hideHeader: true,
        hideNav: true,
      });

      // append to DOM
      await element?.mount("onboarding");

      // subscribe element events
      element?.on("ready", (event) => {
        handleReady(event);
      });
      element?.on("cancel", (event) => {
        // handleCancel(event);
        // ! Changed to Decentro's custom success screen
        handleSuccess();
      });
      element?.on("success", () => {
        handleSuccess();
      });
      element?.on("error", (event) => {
        console.log("on error", event);
        handleError(event);
      });
    };
    mountKybElement();

    return () => element?.destroy();
  }, [kybAuthConfig]);

  return (
    <>
      <div
        style={{ height: "100vh", display: loading ? "none" : "block" }}
        id="onboarding"
      />
      {loading && (
        <Stack spacing={2}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            KYB flow is loading...
          </Typography>
          <LinearProgress color="primary" />
        </Stack>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={2000}
        open={toastOpen}
        message={toastMessage}
        onClose={onToastClose}
      />
    </>
  );
};

export default OnboardingKyb;
