let apiEndpoint = {
  USER_SIGN_IN: {
    endpoint: "/v1/frontend/authentication/user/login",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  USER_SIGN_UP: {
    endpoint: "/v1/frontend/account/business",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_BIZ_ACCOUNT: {
    endpoint: "/v1/frontend/account/business",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GENERATE_AUTH_CODE: {
    endpoint: "/v1/frontend/account/business/airwallex",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
};

export default apiEndpoint;
