import React, { useContext, useEffect, useState } from "react";
// router imports
import { useHistory } from "react-router";
// mui styles and components imports
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
// import { init, createElement } from "@airwallex/platform-onboarding-sdk";
import { init, createElement, ElementTypes } from "@airwallex/components-sdk";
// utils imports
import { getAuthCode } from "../utils/apiClient";
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
} from "../utils";
import OnBoardingContext from "../context/OnBoardingContext";

const ELEMENT_TYPE = "kyc";

const Onboarding = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // ^ context
  const { authConfig, toSuccessCard } = useContext(OnBoardingContext);

  // handle toast close
  const onToastClose = () => {
    setToastOpen(false);
  };

  const handleCancel = () => {
    window.alert("Cancel");
  };

  const handleSuccess = () => {
    // history.push("/success");
    toSuccessCard();
  };

  const handleError = () => {
    setToastMessage("Oops, something is wrong");
    setToastOpen(true);
  };

  const handleReady = (event) => {
    if (event.type === "consent") {
      setLoading(false);
    } else {
      if (event.kycStatus !== "INIT") {
        // history.push("/success");
        toSuccessCard();
      } else {
        setToastMessage("Your verification process has started!");
        setToastOpen(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let element = null;
    const mountKycElement = async () => {
      // const codeVerifier = generateCodeVerifier();
      // const codeChallenge = await generateCodeChallengeFromVerifier(
      //   codeVerifier
      // );
      // const authCode = await getAuthCode(codeChallenge);

      let {
        auth_code: authCode,
        client_id: clientId,
        code_verifier: codeVerifier,
        env,
        lang_key: langKey,
      } = authConfig;

      // initialize AirwallexOnboarding instance on window
      await init({
        authCode,
        codeVerifier,
        env,
        clientId,
        langKey,
        elements: ["kyc"],
        // env: process.env.API_ENV,
        // clientId: process.env.CLIENT_ID,
        // clientId: "3_WGOTp_TOq8fdYIs_tRug",
      });

      // create onboarding element
      element = await createElement("kyc", {
        hideHeader: true,
        hideNav: true,
      });

      // append to DOM
      await element?.mount("onboarding");

      // subscribe element events
      element?.on("ready", (event) => {
        handleReady(event);
      });
      element?.on("cancel", (event) => {
        handleCancel(event);
      });
      element?.on("success", () => {
        handleSuccess();
      });
      element?.on("error", (event) => {
        handleError(event);
      });
    };
    mountKycElement();
    return () => element?.destroy();
  }, [authConfig]);

  return (
    <>
      <div
        style={{ height: "100vh", display: loading ? "none" : "block" }}
        id="onboarding"
      />
      {loading ? (
        <Stack spacing={2}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            KYC flow is loading...
          </Typography>
          <LinearProgress color="primary" />
        </Stack>
      ) : null}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={2000}
        open={toastOpen}
        message={toastMessage}
        onClose={onToastClose}
      />
    </>
  );
};

export default Onboarding;
