import { useEffect } from "react";
// context imports
import { OnBoardingProvider } from "./context/OnBoardingContext";
// page components imports
import OnBoardingLayout from "./components/pages/OnBoardingLayout";
// styles imports
import "./App.css";

function App() {
  const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
  const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

  const isDark = window.matchMedia("(prefers-color-scheme: dark)");

  const changeFavicon = () => {
    if (isDark.matches) {
      document.head.append(darkSchemeIcon);
      lightSchemeIcon.remove();
    } else {
      darkSchemeIcon.remove();
      document.head.append(lightSchemeIcon);
    }
  };

  useEffect(() => {
    changeFavicon();
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      isDark.addListener(changeFavicon);
    } else {
      isDark.addEventListener("change", changeFavicon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <OnBoardingProvider>
        <OnBoardingLayout />
      </OnBoardingProvider>
    </>
  );
}

export default App;
