import React from "react";
// styles imports
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="card-wrapper">
      <div className="loading-heading">
        <p>Loading ...</p>
        <div class="loader-balls">
          <div class="loader-balls__item"></div>
          <div class="loader-balls__item"></div>
          <div class="loader-balls__item"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;